import React from 'react'
import styles from './styles.module.css'
import { useSelector } from 'react-redux'
import { IState } from '../../models/state'

interface IHeaderProps{

}

export const Header: React.FC<IHeaderProps> = (props:IHeaderProps) => {
	
	const name = useSelector((state: IState) => state.client?.name)

	return (
		<div className={styles.Container}>
			<header>				
				<div className={styles.UserInfoContainer}>
					<img alt='' src={require('../../assets/avatar_notif.png')}/>
					<span>Oi, {name}</span>
				</div>	
			</header>
		</div>
	)
}