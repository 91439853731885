import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { IState } from '../../../models/state'
import { Header } from '../../header'
import { notFound } from '../../notFound'
import { ClientData } from '../clientData'
import { AdminHome } from '../home'
import { SideBar } from '../sidebar'
import styles from './styles.module.css'

export const AdminDashboard: React.FC = () => {

	const client = useSelector((state: IState) => state.client)

	if (client === null || client.type !== 'admin') {
		return <Redirect to='/admin'></Redirect>
	}

	return (
		<div className={styles.Container}>
			<div className={styles.Column}>
				<div className={styles.SideBarContainer}>
					<SideBar />
				</div>
			</div>
			<div className={styles.Column}>
				<div className={styles.HeaderContainer}>
					<Header />
				</div>
				<div className={styles.BodyContainer}>
					<Switch>
						<Route path='/admin/logged/home' exact component={AdminHome} ></Route>
						<Route path='/admin/logged/clientdata' exact component={ClientData} ></Route>
						<Route component={notFound} />
					</Switch>
				</div>
			</div>
		</div>
	)
}