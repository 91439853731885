import {IClient} from '../models/client'
import {IClientAction, ClientActionTypes} from '../actions/client'

export const client = (state: IClient | null = null, action: IClientAction): IClient | null => {
	switch (action.type) {
		case ClientActionTypes.Load:
			return {
				...action.client,
			}

		case ClientActionTypes.Unload:
			return null

		default:
			return state
	}
}
