import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import './App.css'
import { Routes } from './routes'

const App: React.FC = () => {

	return (
		<div className='App'>
			<Routes />
			<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} newestOnTop={true}></ToastContainer>
		</div>
	)
}

export default App
