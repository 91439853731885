import { Form, Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { CircleSpinner } from 'react-spinners-kit'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import { ActionCreators } from '../../actions'
import { InputComponent } from '../../components/inputComponent'
import { IClient } from '../../models/client'
import { ILogin } from '../../models/login'
import styles from './styles.module.css'

const validationSchema = Yup.object().shape({
	password: Yup.string()
		.required('Informe uma senha!'),
	email: Yup.string()
		.required('Informe um email!')
})

export const LoginComponent: React.FC = () => {

	const dispatch = useDispatch()

	const history = useHistory()

	const [isLoading, setIsLoading] = useState(false)
	const [isSubmit, setIsSubmit] = useState(false)
	const [formValues, setFormValues] = useState<ILogin | null>(null)
	const [loadingStyle, setLoadingStyles] = useState({})

	const handleSubmit = useCallback((values: ILogin) => {
		setFormValues(values)
		setIsSubmit(true)
		setIsLoading(true)
	}, [])

	useEffect(() => {

		const handleLogin = (value: ILogin) => {

			loginFetch(value)
				.then((data: IClient) => {
					if (data.name) {

						// const name: string = data.name
						// const balance: number = data.balance
						// const idClient: number = data.idClient
						const { name, balance, newTermAcceptedAt, monthCompensation, oldBalance,
						key, clientDetails, registerDate, birthDate, phone, documentCPF, documentRG, address,
						cep, city, state, statement } = data

						dispatch(ActionCreators.Client.load({
							name,
							balance,
							newTermAcceptedAt,
							monthCompensation,
							oldBalance,
							key,
							clientDetails,
							registerDate,
							birthDate,
							phone,
							documentCPF,
							documentRG,
							address,
							cep,
							city,
							state,
							statement
						}))

						history.push('/logged/home')
					} else {
						toast.error('Usuário e/ou senha inválido!')
					// toast.error('Site em manutenção.Tente mais tarde!')
					}
				})
				.catch((error) => {
					console.error(error)
				})
				.finally(() => setIsLoading(false))
		}

		if (isSubmit === true && formValues) {
			handleLogin(formValues)
		}

		return (() => setIsSubmit(false))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmit])

	useEffect(() => {
		if (isLoading) {
			setLoadingStyles({
				backgroundColor: '#ccc'
			})
		} else {
			setLoadingStyles({})
		}
	}, [isLoading])

	const loginFetch = async (value: ILogin) => {

		const response = await fetch('/api/login', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				password: value.password,
				email: value.email
			}),
		})

		const content = response.json()

		return content

	}

	const initialValues: ILogin = {
		email: '',
		password: ''
	}

	return (
		<div className={styles.Container}>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
			>
				{({ errors, handleChange, touched, values }) => (
					<Form className={styles.Form}>
						<label className={styles.Label}>E-mail</label>
						<div className={styles.InputContainer}>
							<InputComponent
								value={values.email}
								type='email'
								onchange={handleChange}
								id='email'
								placeholder='Digite seu e-mail...'
								errorMessage={errors.email && touched.email ? errors.email : ''}
							/>
						</div>
						<label className={styles.Label}>Senha</label>
						<div className={styles.InputContainer}>
							<InputComponent
								value={values.password}
								type='password'
								onchange={handleChange}
								id='password'
								placeholder='Digite sua senha...'
								errorMessage={errors.password && touched.password ? errors.password : ''}
							/>
						</div>
						<div className={styles.ConfirmButtonContainer}>
							<button type='submit' disabled={isLoading} style={loadingStyle}>
								Entrar
								<div
									className={styles.SpinnerContainer}
									style={isLoading ? { display: 'flex' } : { display: 'none' }}
								>
									<CircleSpinner />
								</div>
							</button>
						</div>
						<div className={styles.ForgotPasswordContainer}>
							{/* <a href='#'>Esqueci meu login ou senha</a> */}
						</div>
					</Form>
				)}

			</Formik>
		</div>
	)
}