export class Utils {

	public static timeSpan = (start: string, rand?: string): number => {
		const sec = 1000
		const min = 60 * sec
		const hour = 60 * min
		const day = 24 * hour

		const toMsec = (ts: any) => {
			if (!ts) {
				return 0
			}
			let time = 0
			ts.split(/(\d+\D+)/).filter((f: string) => f !== '').forEach((e: string) => {
				const t = parseInt(e)
				switch (true) {
					case e.includes('day') || e.includes('d'):
						time += t * day
						break
					case e.includes('hour') || e.includes('h'):
						time += t * hour
						break
					case e.includes('min') || e.includes('m'):
						time += t * min
						break
					case e.includes('sec') || e.includes('s'):
						time += t * sec
						break
					default:
						break
				}
			})
			return time
		}

		return toMsec(start) + toMsec(rand) * Math.random()
	}

	public static log = (...logs: any[]) => {
		console.log('===', new Date(), '===')
		console.log(...logs)
		console.log('=== === === ===  === === === ===')
	}

	public static warn = (...warnings: any[]) => {
		console.warn('===', new Date(), '===')
		console.warn(...warnings)
		console.warn('=== === === ===  === === === ===')
	}

	public static error = (...errors: any[]) => {
		console.error('===', new Date(), '===')
		console.error(...errors)
		console.error('=== === === ===  === === === ===')
	}

	public static info = (...infos: any[]) => {
		console.info('===', new Date(), '===')
		console.info(...infos)
		console.info('=== === === ===  === === === ===')
	}

	public static table = (...logs: any[]) => {
		console.log('===', new Date(), '===')
		console.table(...logs)
		console.log('=== === === ===  === === === ===')
	}

	public static pad = (str: string, size: number) => {
		return (new Array(size + 1).join('0') + str).slice(-size)
	}

	public static chunk = (str: string, size: number) => {
		const chunks = new Array(str.length / size + .5 | 0)
		const nChunks = chunks.length

		let newo = 0
		for (let i = 0, o = 0; i < nChunks; ++i, o = newo) {
			newo += size
			chunks[i] = str.substr(o, size)
		}

		return chunks
	}

	public static round = (value: number | string, decimals: number) => {
		const pow = Math.pow(10, decimals)
		if (typeof (value) === 'string') {
			value = parseFloat(value)
		}
		return parseFloat((Math.round(value * pow) / pow) as any)
	}

	public static floor = (value: number | string, decimals: number) => {
		const pow = Math.pow(10, decimals)
		if (typeof (value) === 'string') {
			value = parseFloat(value)
		}
		return parseFloat((Math.floor(value * pow) / pow) as any)
	}

	public static ceil = (value: number | string, decimals: number) => {
		const pow = Math.pow(10, decimals)
		if (typeof (value) === 'string') {
			value = parseFloat(value)
		}
		return parseFloat((Math.ceil(value * pow) / pow) as any)
	}

	public static sleep = (ms: number) => {
		return new Promise((resolve) => setTimeout(resolve, ms))
	}

	public static formatNumber(value: number | string, decimals: number = 2) {
		return value.toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
	}
}
