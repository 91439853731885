import { ILogin } from '../models/login'
import { IClient } from '../models/client'

export enum ClientActionTypes {
	Login = 'Client.Login',
	Logout = 'Client.Logout',
	Load = 'Client.Load',
	Unload = 'Client.Unload',
}

export interface IClientLoginAction {
	type: ClientActionTypes.Login
	login: ILogin
}

export interface IClientLogoutAction {
	type: ClientActionTypes.Logout
}

export interface IClientLoadAction {
	type: ClientActionTypes.Load
	client: IClient
}

export interface IClientUnloadAction {
	type: ClientActionTypes.Unload
}

export class ClientActionCreators {
	public static login = (login: ILogin): IClientLoginAction => ({
		type: ClientActionTypes.Login,
		login
	})

	public static logout = (): IClientLogoutAction => ({
		type: ClientActionTypes.Logout
	})

	public static load = (client: IClient): IClientLoadAction => ({
		type: ClientActionTypes.Load,
		client
	})

	public static unload = (): IClientUnloadAction => ({
		type: ClientActionTypes.Unload
	})
}

export type IClientAction = IClientLoginAction | IClientLogoutAction | IClientLoadAction | IClientUnloadAction