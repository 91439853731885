import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, Store } from 'redux'
import App from './App'
import './index.css'
import { IState } from './models/state'
import { reducers } from './reducers'

export const initialState: IState = {
	client: null
}

export const store: Store<IState> = createStore(
	reducers,
	initialState,
)

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>
	, document.getElementById('root'))
