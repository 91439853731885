import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ActionCreators } from '../../../actions'
import styles from './styles.module.css'

export const SideBar: React.FC = () => {

	const history = useHistory()
	const dispatch = useDispatch()

	const handleOnClickOutHandler = () => {
		dispatch(ActionCreators.Client.unload())
		history.push('/admin')
	}

	return (
		<div className={styles.Container}>
			<div className={styles.LogoContainer}>
				<img src={require('../../../assets/bwa_img_logo_quad.png')} alt='balanceCard' /><br />
			</div>
			<div className={styles.MenuContainer}>
				<p id={styles.textSide}>Recuperação Judicial</p>
				<br />
					<ul className={styles.Menu}>
						<li className={styles.MenuItem} onClick={() => history.push(`/admin/logged/home`)}>
							<HomeOutlinedIcon />
							Home
						</li>
					<li className={styles.MenuItem} onClick={() => history.push(`/admin/logged/clientdata`)}>
						<AccountBalanceWalletOutlinedIcon />
							Clientes
						</li>
						<li className={styles.MenuItem} onClick={() => handleOnClickOutHandler()}>
							<ExitToAppOutlinedIcon />
							Sair
						</li>
					</ul>
			</div>
		</div>
	)
}