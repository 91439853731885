import {ClientActionCreators, ClientActionTypes, IClientAction} from './client'

export class ActionTypes{
	public static Client = ClientActionTypes
}

export class ActionCreators{
	public static Client = ClientActionCreators
}

export type IAction = IClientAction