import React from 'react'
import './styles.module.css'

export const AdminHome: React.FC = () => {

	return (
		<div className='ContainerAdminHome'>
			<div className='container-fluid'>
				<div className='row'>
					Hello, Admin BWA!
				</div>
			</div>
		</div>
	)
}
