import React from 'react'
import styles from './styles.module.css'

export const Contact: React.FC = () => {

	return (
		<div className={styles.Container}>
			<div className={styles.InformationsContainer}>
				<div className={styles.InformationTittle}>
					<h2>Entre em <br />Contato</h2>
					<div className={styles.Line}>
						<hr />
					</div>
				</div>
				<div className={styles.InformationText}>
					<p>Este é o seu canal de comunicação com a BWA Brasil. O que você tem a dizer é muito importante para nós. Conte conosco para tirar dúvidas, registrar elogios e enviar sugestões.</p>
				</div>
				<div className={styles.InformationWhats}>
					<span>+55 (11) 98937.4815</span>
					<span>WhatsApp</span>
				</div>
				<div className={styles.InformationAddress}>
					<span>Rua Carneiro da Cunha, 167 - Conjunto 28, Vila da Saúde</span>
					<span>Endereço - São Paulo(SP)</span>
				</div>
			</div>
			<div className={styles.FormContainer}>
				<div className={styles.Row}>
					<label>
						Seu nome
						<input type='text'></input>
					</label>
				</div>
				<div className={styles.Row}>
					<label>
						Seu e-mail
						<input type='email'></input>
					</label>
				</div>
				<div className={styles.Row}>
					<label>
						Assunto
						<input type='text'></input>
					</label>
				</div>
				<div className={styles.Row}>
					<label>
						Sua mensagem
						<textarea></textarea>
					</label>
				</div>
				<div className={styles.ButtonContainer}>
					<button className={styles.Button}>
						Enviar
					</button>
				</div>
			</div	>
		</div>
	)
}