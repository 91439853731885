import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Utils } from '../../../functions/utils'
import { IState } from '../../../models/state'
import styles from './styles.module.css'

interface ICustomer {
	id_client: number
	email: string
	name: string
	banned: boolean
	newTermAcceptedAt: string
	balance: number
	type: string
}

export const ClientData: React.FC = () => {

	const client = useSelector((state: IState) => state.client)
	const [customers, setCust] = useState<ICustomer[] | null>(null)

	if (client === null || client.type !== 'admin') {
		return <Redirect to='/admin'></Redirect>
	}

	if (!customers) {
		fetch('/api/admin/customers')
			.then((res) => res.json())
			.then((json) => {
				setCust(json)
				console.log({ customers })
			})
	}

	return (
		<div className={styles.Container}>
			<div className={styles.InfoText}>
				<h3>LISTA DE CLIENTES</h3>
				<div className={styles.Table}>
					<ul className={styles.TableHeader}>
						<li>Email</li>
						<li>Nome</li>
						<li>Data Novo Termo</li>
						<li>Saldo</li>
					</ul>
					<br />
					{(customers)
						? customers.map((c) => {
							return (
								<ul className={styles.TableRow}>
									<li>{c.email}</li>
									<li>{c.name}</li>
									{(c.newTermAcceptedAt)
										? <li>{new Date(c.newTermAcceptedAt).toLocaleString('pt-br')}</li>
										: <li></li>
									}
									<li>{Utils.formatNumber(c.balance)}</li>
								</ul>
							)
						})
						: <div></div>
					}
				</div>
			</div>
		</div>
	)
}