import { createBrowserHistory } from 'history'
import React from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { Admin } from './components/admin'
import { ScrollToTop } from './components/scrollToTop'
import { Logged } from './pages/logged'
import { Login } from './pages/login'

export const history = createBrowserHistory()

export const Routes: React.FC = () => {
	return (
    <Router history={history}>
      <Switch>
        <Route path='/logged' component={Logged} />
        <Route path='/admin' component={Admin} />
        <Route path='/' component={Login} />
      </Switch>
      <ScrollToTop />
    </Router>
  )
}
