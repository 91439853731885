import React from 'react'
import { Utils } from '../../functions/utils'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { IState } from '../../models/state'
import styles from './styles.module.css'

export const Statement: React.FC = () => {

	const client = useSelector((state: IState) => state.client)
	if (!client || !client.clientDetails) {
		return <Redirect to='/' />
	}

	const { statement } = client
	console.log(statement[0].date)

	return (
		<div className={styles.Container}>
			<div className={styles.CardContainer}>
				<table className={styles.ClientDetailsTable}>
					<thead className={styles.TableHeader}>
						<th id={styles.TableHeaderDetails}>Depósitos e Saques</th>
					</thead>
				</table>
			</div>
			<div className={styles.FormDetails}>
				<table className='table table-striped'>
					<thead>
						<tr>
							<th scope="col">Data</th>
							<th scope="col">Tipo</th>
							<th scope="col">Valor</th>
						</tr>
					</thead>
					<tbody>
						{statement 
							? statement.map((s) => {
								return (
									<tr>
										<th scope="row">{new Date(s.date!).toLocaleDateString('pt-br')}</th>
										<td>{s.type}</td>
										<td>{Utils.formatNumber(s.amount)}</td>
									</tr>
								)
							})
							: <div></div>}
					</tbody>
				</table>
				{/* <Table dataSource={statement}
					pagination={false}
					size='large'
						>
							<Column title='Data' dataIndex='date' key='date' render={(text, record: any) => (new Date(record.date!).toLocaleDateString('pt-br'))} />
							<Column title='Tipo' dataIndex='type' key='type' />
							<Column title='Valor' dataIndex='amount' key='amount' render={(text, record: any) => (Utils.formatNumber(record.amount.toFixed(2)))} />
						</Table> */}
			</div>
		</div>
	)
}