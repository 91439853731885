import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AdminDashboard } from '../../components/admin/dashboard'
import { Contact } from '../../components/contact'
import { Details } from '../../components/details'
import { Statement } from '../../components/statement'
import { Dashboard } from '../../components/dashboard'
import { Header } from '../../components/header'
import { SideBar } from '../../components/sidebar'
import { Tax } from '../../components/tax'
import { Term } from '../../components/term'
import { IState } from '../../models/state'
import styles from './styles.module.css'

export const Logged: React.FC = () => {

	const client = useSelector((state: IState) => state.client)

	if (client === null) {
		return <Redirect to='/'></Redirect>
	}

	return (
		<div className={styles.Container}>
			<div className={styles.Column}>
				<div className={styles.SideBarContainer}>
					<SideBar />
				</div>
			</div>
			<div className={styles.Column}>
				<div className={styles.HeaderContainer}>
					<Header />
				</div>
				<div className={styles.BodyContainer}>
					<Switch>
						<Route path='/logged/term' exact component={Term} ></Route>
						<Route path='/logged/fale' exact component={Contact} ></Route>
						<Route path='/logged/details' exact component={Details} ></Route>
						<Route path='/logged/statement' exact component={Statement} ></Route>
						<Route path='/logged/ir2019' exact component={Tax} ></Route>
						<Route path='/logged' component={Dashboard} ></Route>
					</Switch>
				</div>
			</div>
		</div>
	)
}