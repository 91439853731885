import React from 'react'
import { LoginComponent } from '../login'
import styles from './styles.module.css'

export const Login: React.FC = () => {
	return (
		<div className={styles.Container}>
			<div className={styles.Row}>
				<div className={styles.LogoContainer}>
					<img src={require('../../../assets/bwa_img_logo.png')} alt='logo' />
				</div>
			</div>
			<div className={styles.Row}>
				<div className={styles.AppImageContainer}>
					<p className={styles.TextCust}>Diante da pandemia causada pela COVID-19, estamos trabalhando em regime de revezamento e home office por questões de segurança para nossos colaboradores. Qualquer dúvida, entrar em contato pelo email: contato@bwabrasil.com.br</p><br></br>
					<p className={styles.TextCust}>Este website é destinado aos clientes da empresa que devem acessar mediante login e senha, a fim de garantirmos privacidade e segurança, em conformidade com a legislação em vigor.</p>
					{/* <img src={require('../../assets/appBwa.png')} alt='app' /> */}
				</div>
				<div className={styles.LoginComponent}>
					<LoginComponent />
				</div>
			</div>
		</div>
	)
}
