import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import imgIR1 from '../../assets/IR1.png'
import imgIR2 from '../../assets/IR2.png'
import imgIR3 from '../../assets/IR3.png'
import { Utils } from '../../functions/utils'
import { IState } from '../../models/state'
import styles from './styles.module.css'

interface IMonthTax {
	month: string
	value: number
	base: number
	earn: number
}

interface ITax {
	balance: number
	baseBalance: number
	byMonth: IMonthTax[]
}

export const Tax: React.FC = () => {

	const client = useSelector((state: IState) => state.client)
	const [tax, setTax] = useState<ITax | null>(null)

	if (!client) {
		return <Redirect to='/' />
	}
	// const { newTermAcceptedAt } = client
	//
	// if (!newTermAcceptedAt) {
	// return <Redirect to='/logged/term' />
	// }

	if (!tax) {
		fetch('/api/ir')
			.then((res) => res.json())
			.then((json) => {
				setTax(json)
				console.log({ tax })
			})
	}

	let total1: number = 0
	let total2: number = 0

	return (
		<div className={styles.Container}>
			<div className={styles.CardContainer}>
				<span>IMPOSTO DE RENDA</span>
				<span className={styles.BalanceText}>2019</span>
			</div>
			<br />
			<div className={styles.InfoBox}>
				<p>Por se tratar de um mercado novo e ainda pendente de regulamentação específica no panorama legislativo brasileiro, os entendimentos expostos estão sujeitos a mudanças futuras no caso de uma manifestação oficial por parte das autoridades fazendárias e/ou regulatórias brasileira.</p>
				<br />
				<p>Em relação à tributação de criptomoedas, o material foi elaborado criteriosamente com base na análise da doutrina e dos precedentes jurisprudenciais e em nossa melhor interpretação dos dispositivos legais aplicáveis. Não representa, por outro lado, uma garantia de que as Autoridades Administrativas e Judiciais Brasileiras concordarão com os argumentos e conclusões ora produzidos.</p>
				<br />
				<p>Os valores abaixo são meramente informativos e devem ser analisados e validados por um contador/advogado contratado pelo Cliente.</p>
				<br />
			</div>
			<div className={styles.InfoText}>
				<hr />
				<h3>COMPOSIÇÃO DA DECLARAÇÃO DE BENS E DIREITOS</h3>
				<br />
				<p>1. Aquisição de <b>{Utils.formatNumber(tax?.balance || 0)}</b> de BWACOINS - criptomoeda</p>
				<br />
				<p>2. Situação em 31/12/2019 <b>{'R$ ' + Utils.formatNumber(tax?.baseBalance || 0)}</b></p>
				<br />
				<h3>COMO DECLARAR</h3>
				<br />
				<div className={styles.ImageContainer}>
					<img className={styles.Image} src={imgIR1} alt='app' />
				</div>
				<br />
				<h5>
					<p>IMPORTANTE: CASO VOCÊ TENHA DECLARADO SUAS BWACOINS NO IMPOSTO DE RENDA 2018/2019, BASTA APENAS ATUALIZAR O PRÓPRIO ITEM DE SUAS BWACOINS NA FICHA BENS E DIREITOS, CONFORME INSTRUÇÕES ACIMA. NÃO HÁ NECESSIDADE DE CRIAR UM ITEM NOVO.</p>
				</h5>
			</div>
			<div className={styles.InfoText}>
				<hr />
				<h3>COMPOSIÇÃO DOS RENDIMENTOS ISENTOS E NÃO TRIBUTÁVEIS</h3>
				<div className={styles.Table}>
					<ul className={styles.TableHeader}>
						<li>Data</li>
						<li>Saque</li>
						<li>Valor do capital</li>
						<li>Ganho de capital</li>
					</ul>
					<br />
					{(tax)
						? tax.byMonth.filter((f) => f.value < 35000).map((m) => {
							total1 += m.earn
							return (
								<ul className={styles.TableRow}>
									<li>{m.month}</li>
									<li>{Utils.formatNumber(m.value)}</li>
									<li>{Utils.formatNumber(m.base)}</li>
									<li>{Utils.formatNumber(m.earn)}</li>
								</ul>
							)
						})
						: <div></div>
					}
					<br />
					<ul className={styles.TableFooter}>
						<li></li>
						<li></li>
						<li>VALOR A DECLARAR:</li>
						<li>R$ {Utils.formatNumber(total1)}</li>
					</ul>
				</div>
				<br />
				<h3>COMO DECLARAR</h3>
				<br />
				<div className={styles.ImageContainer}>
					<img className={styles.Image} src={imgIR2} alt='app' />
				</div>
			</div>

			<div className={styles.InfoText}>
				<hr />
				<h3>COMPOSIÇÃO DOS RENDIMENTOS TRIBUTÁVEIS</h3>
				<div className={styles.Table}>
					<ul className={styles.TableHeader}>
						<li>Data</li>
						<li>Resgate</li>
						<li>Custo de aquisição</li>
						<li>Ganho de capital</li>
					</ul>
					<br />
					{(tax)
						? tax.byMonth.filter((f) => f.value >= 35000).map((m) => {
							total2 += m.earn
							return (
								<ul className={styles.TableRow}>
									<li>{m.month}</li>
									<li>{Utils.formatNumber(m.value)}</li>
									<li>{Utils.formatNumber(m.base)}</li>
									<li>{Utils.formatNumber(m.earn)}</li>
								</ul>
							)
						})
						: <div></div>
					}
					<br />
					<ul className={styles.TableFooter}>
						<li></li>
						<li></li>
						<li>VALOR A DECLARAR:</li>
						<li>R$ {Utils.formatNumber(total2)}</li>
					</ul>
				</div>
				<br />
				<h3>COMO DECLARAR</h3>
				<br />
				<div className={styles.ImageContainer}>
					<img className={styles.Image} src={imgIR3} alt='app' />
				</div>
			</div>
		</div>
	)
}