import React from 'react'
import styles from './styles.module.css'

interface IProps {
	type: 'email' | 'password' | 'number' | 'text'
	id: string
	value?: string | number
	onchange: (value: any) => void
	onclick?: () => void
	placeholder?: string
	errorMessage?: string
}

export const InputComponent: React.FC<IProps> = (props: IProps) => {

	return (
		<div className={styles.Container}>
			<input
				id={props.id}
				name={props.id}
				type={props.type}
				placeholder={props.placeholder ? props.placeholder : ''}
				value={props.value ? props.value : ''}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onchange(event)}
				autoComplete='on'
			/>
			<div style={{ height: '20px', textAlign: 'right' }}>
				{
					props.errorMessage ? <span style={{ color: 'red' }}>{props.errorMessage}</span> : ''
				}
			</div>
		</div>
	)
}
