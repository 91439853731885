import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ActionCreators } from '../../actions'
import { IState } from '../../models/state'
import './index.css'

export const Term: React.FC = () => {

	const dispatch = useDispatch()

	const acceptTermButton = async () => {
		try {
			const result = await fetch('/api/client/acceptTerm').then((res) => res.json())
			if (result.error) {
				toast.error(result.error)
				return
			}
			dispatch(ActionCreators.Client.load(result))

		} catch (error) {
			toast.error('Internal Server Error!')
		}
	}

	const client = useSelector((state: IState) => state.client)
	if (!client) {
		return <Redirect to='/' />
	}

	const { newTermAcceptedAt } = client

	if (newTermAcceptedAt) {
		return <Redirect to='/logged/home' />
	}

	return (
		<div className='term'>
		</div>
	)
}