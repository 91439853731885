import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { notFound } from '../notFound'
import { AdminDashboard } from './dashboard'
import { Login } from './pageLogin'

export const Admin: React.FC = () => {

	return (
		<Switch>
			<Route path='/admin' exact component={Login} ></Route>
			<Route path='/admin/logged' component={AdminDashboard} ></Route>
			<Route component={notFound} />
		</Switch>
	)
}