// import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import DehazeIcon from '@material-ui/icons/Dehaze'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import PhoneCallbackOutlinedIcon from '@material-ui/icons/PhoneCallbackOutlined'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ActionCreators } from '../../actions'
import { IState } from '../../models/state'
import styles from './styles.module.css'

export const SideBar: React.FC = () => {

	const history = useHistory()
	const dispatch = useDispatch()
	const newTermAcceptedAt = useSelector((state: IState) => state.client?.newTermAcceptedAt)

	const handleOnClickOutHandler = () => {
		dispatch(ActionCreators.Client.unload())
		history.push('/')
	}

	const client = useSelector((state: IState) => state.client)

	return (
		<div className={styles.Container}>
			<div className={styles.LogoContainer}>
				<img src={require('../../assets/bwa_img_logo_quad.png')} alt='balanceCard' /><br />
			</div>
			<div className={styles.MenuContainer}>
				<p id={styles.textSide}>Recuperação Judicial</p>
				<br />
				{newTermAcceptedAt
					?
					<ul className={styles.Menu}>
						<li className={styles.MenuItem} onClick={() => history.push(`/logged/home`)}>
							<HomeOutlinedIcon />
							Home
						</li>
						{client?.clientDetails
							?
								<li className={styles.MenuItem} onClick={() => history.push(`/logged/details`)}>
								<HowToRegIcon />
								Dados Pessoais
								</li>
							: <div></div>
						}
						{client?.clientDetails
							?
								<li className={styles.MenuItem} onClick={() => history.push(`/logged/statement`)}>
								<DehazeIcon />
								Depósitos e Saques
								</li>
							: <div></div>
						}
						{/* <li className={styles.MenuItem} onClick={() => history.push(`/logged/ir2019`)}>
							<AccountBalanceOutlinedIcon />
							I.R. 2019
						</li> */}
						<li className={styles.MenuItem} onClick={() => history.push(`/logged/fale`)}>
							<PhoneCallbackOutlinedIcon />
							Fale Conosco
						</li>
						<li className={styles.MenuItem} onClick={() => handleOnClickOutHandler()}>
							<ExitToAppOutlinedIcon />
							Sair
						</li>
					</ul>
					:
					<ul className={styles.Menu}>
						{/* <li className={styles.MenuItem} onClick={() => history.push(`/logged/ir2019`)}>
							<AccountBalanceOutlinedIcon />
							I.R. 2019
						</li> */}
						<li className={styles.MenuItem} onClick={() => history.push(`/logged/term`)}>
							<AssignmentTurnedInOutlinedIcon />
							Termos de Uso
						</li>
						<li className={styles.MenuItem} onClick={() => handleOnClickOutHandler()}>
							<ExitToAppOutlinedIcon />
							Sair
						</li>
					</ul>
				}

			</div>
		</div>
	)
}