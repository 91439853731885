import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { IState } from '../../models/state'
import styles from './styles.module.css'

export const Details: React.FC = () => {

	const client = useSelector((state: IState) => state.client)
	if (!client || !client.clientDetails) {
		return <Redirect to='/' />
	}

	const { name, registerDate, birthDate, phone, documentCPF, documentRG, address, cep, city, state } = client
	const regDate = new Date(registerDate!).toLocaleDateString('pt-br')
	const bDate = new Date(birthDate!).toLocaleDateString('pt-br')

	return (
		<div className={styles.Container}>
			<div className={styles.CardContainer}>
				<table className={styles.ClientDetailsTable}>
					<thead className={styles.TableHeader}>
						<th id={styles.TableHeaderDetails}>Dados Pessoais</th>
					</thead>
				</table>
			</div>
			<div className={styles.FormDetails}>
				<form>
					<div className='form-row'>
						<div className='form-group col-9'></div>
						<div className='form-group col-3'>
							<label id={styles.labelBold}>Data de Cadastro</label>
							<input type='text' className='form-control' value={regDate} id={styles.styledInput} readOnly={true}/>
						</div>
					</div>
					<div className='form-row'>
						<div className='form-group col-9'>
							<label id={styles.labelBold}>Nome</label>
							<input type='text' className='form-control' value={name} id={styles.styledInput} readOnly={true}/>
						</div>
						<div className='form-group col-3'>
							<label id={styles.labelBold}>Data de Nascimento</label>
							<input type='text' className='form-control' value={bDate} id={styles.styledInput} readOnly={true}/>
						</div>
					</div>
					<div className='form-row'>
						<div className='form-group col-4'>
							<label id={styles.labelBold}>CPF</label>
							<input type='text' className='form-control' value={documentCPF} id={styles.styledInput} readOnly={true}/>
						</div>
						<div className='form-group col-4'>
							<label id={styles.labelBold}>RG</label>
							<input type='text' className='form-control' value={documentRG} id={styles.styledInput} readOnly={true}/>
						</div>
						<div className='form-group col-4'>
							<label id={styles.labelBold}>Telefone</label>
							<input type='text' className='form-control' value={phone} id={styles.styledInput} readOnly={true}/>
						</div>
					</div>
					<div className='form-row'>
						<div className='form-group col-12'>
							<label id={styles.labelBold}>Endereço</label>
							<input type='text' className='form-control' value={address} id={styles.styledInput} readOnly={true}/>
						</div>
					</div>
					<div className='form-row'>
						<div className='form-group col-4'>
							<label id={styles.labelBold}>CEP</label>
							<input type='text' className='form-control' value={cep} id={styles.styledInput} readOnly={true}/>
						</div>
						<div className='form-group col-4'>
							<label id={styles.labelBold}>Cidade</label>
							<input type='text' className='form-control' value={city} id={styles.styledInput} readOnly={true}/>
						</div>
						<div className='form-group col-4'>
							<label id={styles.labelBold}>Estado</label>
							<input type='text' className='form-control' value={state} id={styles.styledInput} readOnly={true}/>
						</div>
					</div>
				</form>
				{/* <Form
					form={form}
					layout='horizontal'
					initialValues={{ name, regDate, bDate, phone, documentCPF, documentRG, address, cep, city, state }}
				>
					<Form.Item>
						<Form.Item name='name' label='Nome' rules={[{ required: true },]}>
							<Input style={{ width: '70%' }} />
						</Form.Item>
						<Form.Item name='bDate' label='Data de Nascimento' rules={[{ required: true },]}>
							<Input style={{ width: '30%' }} />
						</Form.Item>
					</Form.Item>
					<Form.Item>
						<Form.Item name='documentCPF' label='CPF' rules={[{ required: true },]}>
							<Input style={{ width: '35%' }} />
						</Form.Item>
						<Form.Item name='documentRG' label='RG' rules={[{ required: true },]}>
							<Input style={{ width: '35%' }} />
						</Form.Item>
						<Form.Item name='phone' label='Telefone' rules={[{ required: true },]}>
							<Input style={{ width: '30%' }} />
						</Form.Item>
					</Form.Item>
					<Form.Item>
						<Form.Item name='address' label='Endereço' rules={[{ required: true },]}>
							<Input style={{ width: '100%' }} />
						</Form.Item>
					</Form.Item>
					<Form.Item>
						<Form.Item name='cep' label='CEP' rules={[{ required: true },]}>
							<Input style={{ width: '30%' }} />
						</Form.Item>
						<Form.Item name='city' label='Cidade' rules={[{ required: true },]}>
							<Input style={{ width: '35%' }} />
						</Form.Item>
						<Form.Item name='state' label='Estado' rules={[{ required: true },]}>
							<Input style={{ width: '35%' }} />
						</Form.Item>
					</Form.Item>
					<Form.Item name='regDate' label='Data de Cadastro' rules={[{ required: true },]}>
						<Input style={{ width: 350 }} />
					</Form.Item>
				</Form> */}
			</div>
		</div>
	)
}