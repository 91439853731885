import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Utils } from '../../functions/utils'
import { IState } from '../../models/state'
import styles from './styles.module.css'

export const Dashboard: React.FC = () => {

	const client = useSelector((state: IState) => state.client)
	if (!client) {
		return <Redirect to='/' />
	}
	const { balance, newTermAcceptedAt, oldBalance, monthCompensation } = client

	// if (!newTermAcceptedAt) {
	// 	return <Redirect to='/logged/term' />
	// }

	const formatedBalance = Utils.formatNumber(balance)
	const formatedOldBalance = Utils.formatNumber(oldBalance)
	const formatedMonthCompensation = Utils.formatNumber(monthCompensation)

	return (
		<div className={styles.Container}>
			<div className={styles.CardContainer}>
				<table>
					<thead className={styles.TableHeader}>
						<th>Saldo Atual</th>
						{/* <th id={styles.Aright}>Maio/2020</th> */}
					</thead>
					<tbody>
						<tr>
							<td><span><img id={styles.ImgBalanceText} src={require('../../assets/logo-coin.png')} alt='logo' /> </span>
								{(balance === 0)
									? <span id={styles.BalanceText0}> - </span>
									: <span id={styles.BalanceText}>{formatedBalance}</span>
								}
							</td>
							{/* <td id={styles.Aright}><span><img id={styles.ImgOldBalanceText} src={require('../../assets/logo-coin.png')} alt='logo' /> </span><span id={styles.OldBalanceText}>{formatedMonthCompensation}</span></td> */}
						</tr>
						<tr>
							{(balance === 0)
								? <td><span id={styles.BalanceText}>Sacado</span> </td>
								: <td></td>
							}
							{/* <td className={styles.TableHeader} id={styles.Aright}>Saldo Anterior</td> */}
						</tr>
						<tr id={styles.Aright}>
							<td></td>
							{/* <td><span><img id={styles.ImgOldBalanceText} src={require('../../assets/logo-coin.png')} alt='logo' /> </span><span id={styles.OldBalanceText}>{formatedOldBalance}</span></td> */}
						</tr>
					</tbody>
				</table>
			</div>
			<div className={styles.AnnouncementTextContainer}>
				<p>Prezado cliente,</p>
				<br />
				<p>Informamos que nosso site teve sua manutenção encerrada e você já pode acessar sua conta com seu login e senha cadastrado anteriormente.</p>
				<br />
				<p>Estamos contente com nosso processo de reestruturação, mas sabemos que ainda teremos um trabalho arduo pela frente, entretanto contamos com um time de consultores e advogados especializados que estão nos auxiliando para que possamos seguir sempre com segurança.</p>
				<br />
				<p>Gostaríamos de agradecer a participação de nossos clientes na vídeo conferência e esperamos que suas principais dúvidas tenham sido sanadas. Caso você ainda possua alguma dúvida, estamos à disposição para atendê-lo.</p>
				<br />
				<p>Nosso atendimento é realizado em horário comercial de segunda à sexta-feira através do número de Whatsapp +55 11 98937.4815.  Ou pelo email faleconosco@bwabrasil.com.br.</p>
				<br />
				<p>Atenciosamente,</p>
				<p>A DIRETORIA</p>
			</div>
		</div>
	)
}